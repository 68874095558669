<!-- 筛选品牌 -->
<template>
  <div class="BrandSelect">
    <!-- 索引列表 -->
    <van-index-bar
      :index-list="indexList"
      @select="indexSelect"
      :sticky-offset-top="barToTop"
      ref="indexBar"
    >
      <template v-for="(ele, key) in brandList">
        <van-index-anchor :key="key" :index="key" />
        <van-cell
          v-for="item in ele"
          :key="item.masterBrandId"
          :title="item.masterBrandName"
          @click="nextPage(item)"
        />
      </template>
    </van-index-bar>
    <!-- 淡入字母显示 -->
    <LetterFadeIn ref="LetterFadeIn" :activeLetter="activeLetter" />
  </div>
</template>

<script>
import LetterFadeIn from "@/components/LetterFadeIn.vue";
import { getCityAndValidityList } from "@/api/api_home";
import { mapState } from "vuex";
import IndexScroll from "@/utils/indexScroll";
export default {
  name: "BrandSelect",
  data() {
    return {
      activeLetter: "A", // 当前高亮索引
      indexList: [],
      brandList: [],
      currentCity: this.$store.state.position,
      barToTop: 0
    };
  },
  computed: {
    ...mapState(["isShowDownLoad"])
  },
  watch: {
    isShowDownLoad() {
      this.barToTop = this.$tools.calcTopHeight();
    }
  },
  created() {
    this.getBrandList();
  },
  mounted() {
    this.barToTop = this.$tools.calcTopHeight();
  },
  methods: {
    // 选择品牌
    nextPage(item) {
      this.$router.replace({
        name: "CarList",
        query: { brandId: item.masterBrandId }
      });
    },
    // 选择索引
    indexSelect(item) {
      this.activeLetter = item;
      this.$refs.LetterFadeIn.letterClick();
      IndexScroll(item, this);
    },
    // 获取品牌列表
    getBrandList() {
      getCityAndValidityList({
        indexName: "carValidity",
        cityId: this.currentCity.cityId
      }).then(res => {
        if (res.code === 0) {
          const ls = this.$tools.handleArrayClassify(res.data.info, "initial");
          this.brandList = ls.arr;
          this.indexList = ls.indexList;
        }
      });
    }
  },
  components: { LetterFadeIn }
};
</script>
