<!-- 用于索引列表展示淡入字母 -->
<template>
  <transition name="van-fade">
    <div class="LetterfadeIn" v-show="visible">{{ activeLetter }}</div>
  </transition>
</template>
<script>
export default {
  name: "LetterfadeIn",
  props: { activeLetter: { type: String } },
  data() {
    return { visible: false, timer: null };
  },
  methods: {
    letterClick() {
      this.visible = true;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.visible = false;
        this.timer = null;
        clearTimeout(this.timer);
      }, 300);
    }
  }
};
</script>
<style lang="scss" scoped>
.LetterfadeIn {
  position: fixed;
  z-index: 50;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 0.8rem;
  height: 0.8rem;
  text-align: center;
  line-height: 0.8rem;
  background: rgba($color: #000000, $alpha: 0.5);
  border-radius: 0.08rem;
  font-size: 0.32rem;
  color: #ffffff;
}
</style>
