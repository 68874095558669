// 解决索引列表 sticky 引起的瞄点定位错误的问题

export default (item, _this) => {
  const list = _this.$refs.indexBar.children;
  const activeItem = list.filter(k => {
    return item === k.index;
  });
  const scrollTop = activeItem[0].$el.offsetTop;
  window.scrollTo(0, scrollTop - _this.$refs.indexBar.stickyOffsetTop);
};
